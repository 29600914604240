import { AnalyticsEvents, AnalyticsEventDefinitions, AnalyticsProperties } from "./analytics-events";
import { Analytics, AnalyticPropertyValues } from "ynab_shared_library/app/base/utilities/Analytics";
import { logAnalyticsEvent, loginAnalytics, logoutAnalytics } from "ynab_shared_library/app/web/utilities/analytics";
import { loadGoogleAds } from "./lib/googleTag";

(() => {
    const RETRY_DELAY_TIME = 100;

    class YNABAnalytics {
        retryWithDelay(fn: () => void) {
            return window.setTimeout(() => {
                fn();
            }, RETRY_DELAY_TIME);
        }

        reportError(error) {
            return window.Rollbar && window.Rollbar.error(error);
        }

        trackEvent(
            eventName: AnalyticsEvents,
            eventProperties: AnalyticPropertyValues = {},
            options = { flush: false },
        ) {
            logAnalyticsEvent(eventName, eventProperties, options);
        }

        initMobile() {
            this.trackEvent = this.trackEventMobile;
        }

        trackEventMobile(eventName, eventProperties: AnalyticPropertyValues = {}, _options) {
            const eventDefinition = AnalyticsEventDefinitions[eventName];
            const supportedProperties = Analytics.filterSupportedProperties(eventDefinition, eventProperties || {});

            YNABMobile.trackEvent(eventDefinition.name, supportedProperties);
        }

        // Track login event and set up analytics related services when logging in.
        trackLogin(provider: string, userId: string) {
            loginAnalytics(userId);
            this.trackEvent(
                AnalyticsEvents.General_LoggedIn,
                { [AnalyticsProperties.LogInMethod]: this._authenticationMethod(provider) },
                { flush: true },
            );
        }

        trackLogout() {
            this.trackEvent(AnalyticsEvents.General_LoggedOut, {}, { flush: true });
        }

        /**
         * Logs out from Analytics integrations.
         * When loading Analytics it accepts the current user ID and it automatically updates
         * the session based on the received user ID, or sets it to null if not available.
         * This method only needs to get called when the user logs out asynchronously and they are
         * not redirected to a different page.
         */
        logoutAnalytics() {
            logoutAnalytics();
        }

        logoutSelfService() {
            if (window.YNAB_CLIENT_CONSTANTS) {
                return YNAB.logoutSelfService();
            }
        }

        // Track sign up event and set up analytics related services as if logging in.
        trackSignup(provider, userId) {
            this.sendRegistrationEventAndTrackConversion();

            loginAnalytics(userId);
            this.trackEvent(
                AnalyticsEvents.SignUp_CompletedSignUp,
                {
                    [AnalyticsProperties.SignUpMethod]: this._authenticationMethod(provider),
                },
                { flush: true },
            );
        }

        /**
         * This registration tracking matches the marketing site implementation,
         * in case anything changes here the other places should be reviewed as well.
         *
         * We only trigger the events in case the functions are defined, because in
         * development by default we disable analytics.
         *
         * TODO: Extract this duplication on tracking to a single place that can be
         * shared among the server and marketing site.
         */
        sendRegistrationEventAndTrackConversion() {
            if (window.gtag) {
                // Send event to Google Analytics
                window.gtag("event", "Registered", {
                    event_category: "Trial Registration",
                    value: 22,
                });

                // send conversion to Google Ads
                window.gtag("event", "conversion", {
                    send_to: "AW-1070740767/v4X9CIWfygIQn-rI_gM",
                    aw_remarketing_only: false,
                });
            }

            if (window.fbq) {
                window.fbq("trackCustom", "Trial Registration");
            }
        }

        loadSignupTracking() {
            // exposed via YNABAnalytics to maintain consistency on the app using the global YNABAnalytics interface
            loadGoogleAds();
        }

        _authenticationMethod(provider) {
            return provider == "apple" ? "Apple" : provider == "google" ? "Google" : "Email";
        }
    }

    window.YNABAnalytics = new YNABAnalytics();
})();
