import * as amplitude from "@amplitude/analytics-browser";
import { ValidPropertyType } from "@amplitude/analytics-types";

declare global {
    interface Window {
        __ynabAmplitude: amplitude.Types.BrowserClient;
    }
}
window.__ynabAmplitude = window.__ynabAmplitude || amplitude.createInstance();

export interface AmplitudeConfig {
    apiKey: string;
    appVersion: string;
    userId?: string;
}

export const Amplitude = {
    init(config: AmplitudeConfig) {
        if (config.apiKey) {
            void window.__ynabAmplitude
                .init(config.apiKey, {
                    appVersion: config.appVersion,
                    autocapture: {
                        sessions: true,
                    },
                })
                .promise.then(this._initUser.bind(this, config));

            // Since events are sent in batches, ensure they're flushed in the background before navigating away from the page.
            // https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/migration/#flush-or-onexitpage
            // https://www.docs.developers.amplitude.com/data/sdks/browser-2/#set-the-transport-to-use-beacon-only-when-exiting-page
            window.addEventListener("pagehide", () => {
                window.__ynabAmplitude.setTransport("beacon");
                window.__ynabAmplitude.flush();
            });
        }
    },

    _initUser(config: AmplitudeConfig, _result: any) {
        const amplitudeUserId = window.__ynabAmplitude.getUserId();
        const currentUserId = config.userId;

        if (amplitudeUserId && amplitudeUserId !== currentUserId) {
            // Amplitude knows about a previously set user, but we either don't have one logged in, or it's
            // somehow not the same. Reset everything to generate a new device id and nullify the user.
            this.reset();
        }

        if (currentUserId) {
            // Ensure the user is always set to the currently logged in one, even if we triggered a reset above.
            this.setUserId(currentUserId);
        }
    },

    flush() {
        window.__ynabAmplitude.flush();
    },

    identify(userProperties: any) {
        const identify = new amplitude.Identify();
        Object.entries(userProperties).forEach(([name, value]) => {
            identify.set(name, value as ValidPropertyType);
        });
        window.__ynabAmplitude.identify(identify);
    },

    reset() {
        window.__ynabAmplitude.reset();
    },

    setUserId(id: string | undefined) {
        window.__ynabAmplitude.setUserId(id);
    },

    track(eventName: string, eventProperties: any) {
        window.__ynabAmplitude.track(eventName, eventProperties);
    },
};
